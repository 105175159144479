<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="350">
      <v-card max-width="350">
        <v-card-text class="pt-3" style="font-size: 16px">
          <h3>{{ contactHeader }}</h3>
          <div class="" v-html="contactText"></div>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-space-between">
          <v-btn
            elevation="0"
            style="width: 100%"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: 'ตกลง'
    }
  },
  data () {
    return {
      dialog: false,
      contactHeader: 'แขวงทางหลวงพิเศษระหว่างเมือง',
      contactText: `ถนนหลวงแพ่ง แขวงทับยาว <br />
        เขตลาดกระบัง กรุงเทพฯ 10520 <br />
        โทร 02-360-7865 <br />
        Fax.02-360-7865`
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.dialog = newVal
      }
    }
  },
  methods: {
    cancel () {
      this.dialog = false
      this.$emit('cancel')
    }
  }
}
</script>
